import { ReactNode, } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import type { WrapPageElementBrowserArgs } from 'gatsby'
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import styled from '@mui/material/styles/styled';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import ImageIcon from '@mui/icons-material/Image';
import { LinkForI18n as Link } from './from-gatsby-theme-material-ui/link';
import GalleryLinkCalender from './gallery-link-calender';
import Changer from '../components/i18n-changer';
import Helmet from 'react-helmet';

interface ILayout {
  children: ReactNode,
  pageProps: WrapPageElementBrowserArgs["props"]
}

const StickyFooterContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column"
}));

const MainForStickyFooter = (props: any) => (
  <Box
    component={"main"}
    mb={2}
    sx={{
      flex: 1
    }}
    {...props}
  >
  </Box>
)

export default function Layout({ children, pageProps }: ILayout) {

  const localeData = (pageProps.data as any)?.localesLayout?.nodes[0]?.data;
  const localeDataObj = JSON.parse(localeData || "{}");
  const pageI18n = pageProps.pageContext.i18n as any;

  return (
    <>
      <GatsbySeo
        titleTemplate={localeDataObj.titleTemplate}
        language={pageI18n.language}
        openGraph={{
          site_name: localeDataObj.openGraphSiteName
        }}
        metaTags={[
          {
            name: "rating",
            content: "adult"
          }
        ]}
      />
      {/* <Helmet>
        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6603887334605985"
          crossOrigin="anonymous"></script>
      </Helmet> */}
      <StickyFooterContainer>
        <AppBar color="primary" position="sticky">
          <Toolbar>
            <Link
              underline='none'
              to="/"
              sx={{
                color: "white",
                display: 'flex',
                columnGap: "0.5em",
              }}
              language={pageI18n.language}
            >
              <>
                <ImageIcon sx={{
                  verticalAlign: "middle",
                  padding: { xs: '8px', sm: "initial" },
                  width: { xs: 'calc(1em + 16px)', sm: "1em" },
                  height: { xs: 'calc(1em + 16px)', sm: "1em" },
                }} />
                <Typography sx={{
                  display: { xs: 'none', sm: 'flex' },
                }}>
                  {localeDataObj.siteDigest}
                </Typography>
              </>
            </Link>
            <Box flex={1}>
            </Box>
            <Box display="flex">
              <Changer languages={pageI18n.languages} originalPath={pageI18n.originalPath}></Changer>
              <GalleryLinkCalender
                currentPath={pageProps.path}
                language={pageI18n.language}
                calenderHeader={localeDataObj.calenderHeader}
              ></GalleryLinkCalender>
            </Box>
          </Toolbar>
        </AppBar>
        <MainForStickyFooter>
          <Container>
            {children}
          </Container>
        </MainForStickyFooter>
        <Box component={"footer"}>
          <Paper elevation={3}>
            <Box display="flex" >
              <Typography
                color="textSecondary"
                sx={{ display: "inline-block", m: "auto", py: "5px", fontSize: 12 }}
              >
                {localeDataObj.siteDigest}
              </Typography>
            </Box>
          </Paper>
        </Box>
      </StickyFooterContainer>
    </>

  );
}
